import React, { useState, useEffect, useRef, useCallback } from 'react';

import classnames from 'classnames';
import { Tag } from '@andes/tag';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../link';
import { namespace, SPACING, textCarouselPropTypes } from './constants';
import withComponentHandler from '../../../../hocs/with-component-handler';
/**
 * @typedef {Object} TextCarouselProps
 * @property {Object} filter - Los datos del filtro asociado al carrusel.
 * @property {Object[]} filter.values - Los valores asociados al filtro.
 * @property {string} filter.values[].id - El identificador único del valor.
 * @property {Object} filter.values[].label - La etiqueta asociada al valor.
 * @property {string} filter.values[].label.text - El texto de la etiqueta.
 * @property {string} filter.values[].target - El enlace asociado al valor.
 * @property {boolean} loading - Indica si el carrusel está en estado de carga.
 */

/**
 * Componente que renderiza un carrusel de texto basado en los valores del filtro.
 *
 * @param {TextCarouselProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el carrusel de texto.
 */
const TextCarousel = ({ filter }) => {
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);

  const handleScrollToElement = useCallback(() => {
    if (ref.current) {
      const element = ref.current.querySelectorAll(`.${namespace}__link`)[filter.scroll_position || 0];

      if (element) {
        element.scrollIntoView({ block: 'nearest', inline: 'center' });
      }
    }
  }, [ref, filter.scroll_position]);

  useEffect(() => {
    handleScrollToElement();
    setLoading(false);
  }, [filter.scroll_position, handleScrollToElement]);

  return (
    <div ref={ref}>
      <CarouselFree spacing={SPACING} className={`${namespace}-carousel`}>
        {filter.values.map((slide) => (
          <CarouselFreeSlide key={slide.id} className={`${namespace}-text-item-slide`}>
            <Link className={`${namespace}__link`} href={slide.target} title={slide.label.text}>
              <Tag className={classnames({ skeleton: loading })} label={slide.label.text} />
            </Link>
          </CarouselFreeSlide>
        ))}
      </CarouselFree>
    </div>
  );
};

TextCarousel.propTypes = {
  ...textCarouselPropTypes,
};

export default withComponentHandler(TextCarousel, { componentName: 'TextCarousel' });
