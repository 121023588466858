import { MODAL_TYPES } from '../constants';

const getGridModifier = (filterId) => {
  switch (filterId) {
    case 'MAIN_COLOR':
    case 'SIZE':
      return 'grid-rows';

    default:
      return 'grid-columns';
  }
};

export const getContentModifier = (modalType, filterId) => {
  switch (modalType) {
    case MODAL_TYPES.WITH_SEARCH:
      return 'list';
    case MODAL_TYPES.DEFAULT:
      return getGridModifier(filterId);

    default:
      return null;
  }
};
