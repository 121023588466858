import { MODAL_TYPES } from '../constants';
import { filterLetter } from './searchUtils';

export const getGrouper = (modalType) => {
  switch (modalType) {
    case MODAL_TYPES.WITH_SEARCH:
      return filterLetter;
    case MODAL_TYPES.DEFAULT:
      return null;

    default:
      return null;
  }
};
