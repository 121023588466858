import React from 'react';
import { shape, string } from 'prop-types';

import { Tag } from '@andes/tag';
import { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../../link';
import { namespace } from '../constants';
import withComponentHandler from '../../../../../hocs/with-component-handler';
/**
 * @typedef {Object} ColorCarouselSlideProps
 * @property {Object} [slide] - Los datos del slide del carrusel.
 * @property {string} [slide.id] - El identificador único del slide.
 * @property {Object} [slide.label] - La etiqueta asociada al slide.
 * @property {string} [slide.label.text] - El texto de la etiqueta.
 * @property {string} [slide.target] - El enlace asociado al slide.
 * @property {string} [slide.value_id] - El identificador del valor del color.
 */

/**
 * Componente que renderiza un slide del carrusel de colores.
 *
 * @param {ColorCarouselSlideProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el slide del carrusel de colores.
 */
const ColorCarouselSlide = ({ slide }) => {
  const { id, target, label, value_id } = slide;
  const { text } = label;

  return (
    <CarouselFreeSlide key={id} className={`${namespace}-color-item-slide`}>
      <Link className={`${namespace}__link`} href={target} filterId={id} title={text}>
        <Tag asset={{ text: ' ' }} label={text} className={`${namespace}-tag-color-${value_id}`} />
      </Link>
    </CarouselFreeSlide>
  );
};

ColorCarouselSlide.propTypes = {
  slide: shape({
    id: string,
    label: shape({
      text: string,
    }),
    target: string,
    value_id: string,
  }),
};

export default withComponentHandler(ColorCarouselSlide, { componentName: 'ColorCarouselSlide' });
