import React from 'react';
import { string } from 'prop-types';

import { Checkbox } from '@andes/checkbox';

import { namespace } from '../../constants';

const MultiSelectionRenderer = ({ id, name, handleSelectValue, isSelected }) => (
  <div className={`${namespace}-filter ${namespace}-filter--multi-selection`} key={id}>
    <Checkbox
      className={`${namespace}-filter-name`}
      inputProps={{ name, value: name }}
      label={<span>{name}</span>}
      checked={isSelected}
      onChange={(e) => handleSelectValue(id, e)}
    />
  </div>
);

MultiSelectionRenderer.propTypes = {
  handleSelectValue: string,
  id: string,
  isSelected: string,
  name: string,
};

export default MultiSelectionRenderer;
