import React from 'react';
import { string } from 'prop-types';

import { Image } from 'nordic/image';

import { FilterLink } from '../../../../../link';
import { namespace } from '../../constants';

const renderImageFilter = ({ name, url, id, logo_url }) => (
  <FilterLink className={`${namespace}-image-filter`} href={url} key={id} title={name}>
    <div className={`${namespace}-image-filter__container`}>
      <Image alt={name} className={`${namespace}-image-filter__image`} src={logo_url} lowEnd />
    </div>
    <div className={`${namespace}-image-filter__info`}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </div>
  </FilterLink>
);

const FilterLinkRenderer = (props) => {
  const { name, url, id, logo_url } = props;

  if (logo_url) {
    return renderImageFilter(props);
  }

  return (
    <FilterLink className={`${namespace}-filter`} href={url} key={id} title={name}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </FilterLink>
  );
};

FilterLinkRenderer.propTypes = {
  id: string,
  logo_url: string,
  name: string,
  url: string,
};

export default FilterLinkRenderer;
