import { shape, string, arrayOf } from 'prop-types';

const filterIntervensionDesktopPropTypes = {
  deviceType: string,
  intervention: shape({
    filter: shape({
      see_all_title: shape({
        text: string,
      }),
      see_all_text: shape(),
      expanded_values: arrayOf(shape()),
    }).isRequired,
    title: shape({
      text: string,
    }).isRequired,
    type: string.isRequired,
  }),
};

const filterIntervensionMobilePropTypes = {
  intervention: shape({
    filter: shape({
      see_all_title: shape({
        text: string,
      }),
      see_all_text: shape(),
      expanded_values: arrayOf(shape()),
    }).isRequired,
    title: shape({
      text: string,
    }).isRequired,
    type: string.isRequired,
  }),
};

export { filterIntervensionDesktopPropTypes, filterIntervensionMobilePropTypes };
