import React from 'react';

import classnames from 'classnames';

import { namespace, filterIntervensionMobilePropTypes } from './constants';
import { renderAvailableFilters, renderInterventionContent } from './components';
import withComponentHandler from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} FilterInterventionMobileProps
 * @property {Object} intervention - Los datos de la intervención del filtro.
 * @property {string} intervention.type - El tipo de intervención.
 * @property {Object} intervention.title - El título de la intervención.
 * @property {string} intervention.title.text - El texto del título.
 * @property {Object} intervention.filter - Los datos del filtro asociado a la intervención.
 * @property {string} intervention.filter.id - El identificador único del filtro.
 * @property {Object} intervention.filter.see_all_title - El título del enlace "Ver todos".
 * @property {string} intervention.filter.see_all_title.text - El texto del enlace "Ver todos".
 * @property {string} intervention.background_color - El color de fondo de la intervención.
 */

/**
 * Componente que renderiza una intervención de filtro para dispositivos móviles.
 *
 * @param {FilterInterventionMobileProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención de filtro.
 */
const FilterIntervention = ({ intervention }) => {
  const { type, title, filter, background_color } = intervention;
  const isTopBrand = filter.id === 'BRAND' && type === 'FILTER_SPECIALIZED';

  return (
    <div className={classnames(namespace, `${namespace}-container`, `${namespace}-container__${type}`)}>
      <div
        className={classnames(
          `${namespace}-background`,
          background_color ? `${namespace}-${background_color}` : `${namespace}-gray-040-solid`,
        )}
      >
        <span className={`${namespace}-title`}>
          {filter?.see_all_title?.text}
          <span className={`${namespace}-title__SEMIBOLD`}> {title.text}</span>
        </span>
        {renderAvailableFilters(filter, title, isTopBrand)}
        {renderInterventionContent(type, filter, title, isTopBrand)}
      </div>
    </div>
  );
};

FilterIntervention.propTypes = {
  ...filterIntervensionMobilePropTypes,
};

export default withComponentHandler(FilterIntervention, { componentName: 'FilterIntervention' });
