/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';

import get from 'lodash/get';

import { CAROUSEL_SLIDES_TO_SHOW, CAROUSEL_SIZE_MODIFIER, CAROUSEL_VIEW_MORE_TEXT_FN } from './constants';

const getSlidesToShow = (carouselType, layoutId, resolution) => {
  const defaultSlidesByTypeAndLayout = get(
    CAROUSEL_SLIDES_TO_SHOW,
    `${carouselType}.${layoutId}.default`,
    CAROUSEL_SLIDES_TO_SHOW.DEFAULT_SLIDES,
  );

  if (!resolution) {
    return defaultSlidesByTypeAndLayout;
  }

  const resolutions = get(CAROUSEL_SLIDES_TO_SHOW, `${carouselType}.${layoutId}.referenceResolutions`, []);

  let referenceResolution = get(resolutions, 'referenceResolutions[0]');

  resolutions.forEach((res) => {
    if (res >= resolution) {
      referenceResolution = res;
    }
  });

  if (!referenceResolution) {
    return defaultSlidesByTypeAndLayout;
  }

  return get(CAROUSEL_SLIDES_TO_SHOW, `${carouselType}.${layoutId}.slidesByResolution.${referenceResolution}`, 0);
};

const getCarouselModifier = (carouselType, slidesToShow) =>
  get(CAROUSEL_SIZE_MODIFIER, `${carouselType}.${slidesToShow}`, CAROUSEL_SIZE_MODIFIER.DEFAULT_MODIFIER);

const useCarouselBreakpoints = (carouselType, layoutId, totalSlides) => {
  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow(carouselType, layoutId));

  const updateSlidesToShow = () => {
    setSlidesToShow(getSlidesToShow(carouselType, layoutId, window.innerWidth));
  };

  useEffect(() => {
    updateSlidesToShow();
    window.addEventListener('resize', updateSlidesToShow);

    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, [updateSlidesToShow]);

  let actualSlidesToShow = slidesToShow;

  if (totalSlides < slidesToShow) {
    actualSlidesToShow = totalSlides;
  }

  const getCarouselViewMoreText = get(CAROUSEL_VIEW_MORE_TEXT_FN, carouselType, CAROUSEL_VIEW_MORE_TEXT_FN.DEFAULT_FN);

  return {
    slidesToShow: actualSlidesToShow,
    modifier: getCarouselModifier(carouselType, actualSlidesToShow),
    viewMoreText: getCarouselViewMoreText(slidesToShow, totalSlides),
  };
};

export default useCarouselBreakpoints;
