import React from 'react';

import TextCarousel from '../text-carousel/mobile';
import ColorCarousel from '../color-carousel/mobile';
import SpecializedCarousel from '../specialized-carousel/mobile';
import { mapFilter } from '../helpers';
import { FILTER_INTERVENTION } from '../constants';

export const renderInterventionContent = (type, filter, title, isTopBrand) => {
  switch (type) {
    case FILTER_INTERVENTION.TYPES.FILTER_COLOR:
      return <ColorCarousel filter={filter} />;
    case FILTER_INTERVENTION.TYPES.FILTER_SPECIALIZED:
      return (
        <SpecializedCarousel
          filter={filter}
          title={title}
          isTopBrand={isTopBrand}
          availableFilters={filter.expanded_values && mapFilter(title, filter.expanded_values)}
        />
      );

    default:
      return <TextCarousel filter={filter} />;
  }
};
