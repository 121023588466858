import React from 'react';

import { CarouselFree } from '@andes/carousel-free';

import ColorCarouselSlide from './components/slides';
import { namespace, colorCarouselPropTypes, SPACING } from './constants';
import withComponentHandler from '../../../../hocs/with-component-handler';
/**
 * @typedef {Object} ColorCarouselProps
 * @property {Object} filter - Los datos del filtro de colores.
 * @property {Object[]} filter.values - Los valores asociados al filtro.
 * @property {string} filter.values[].id - El identificador único del valor.
 * @property {string} filter.values[].label - La etiqueta asociada al valor.
 * @property {string} filter.values[].target - El enlace asociado al valor.
 */

/**
 * Componente que renderiza un carrusel de colores basado en los valores del filtro.
 *
 * @param {ColorCarouselProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el carrusel de colores.
 */
const ColorCarousel = ({ filter }) => {
  const { values } = filter;

  return (
    <CarouselFree spacing={SPACING} className={`${namespace}-carousel`}>
      {values.map((slide) => (
        <ColorCarouselSlide key={slide.id} slide={slide} />
      ))}
    </CarouselFree>
  );
};

ColorCarousel.propTypes = {
  ...colorCarouselPropTypes,
};

export default withComponentHandler(ColorCarousel, { componentName: 'ColorCarousel' });
