import { useState } from 'react';

const useModal = (defaultIsOpen = false) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  function openModal(e) {
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    e.preventDefault();
    setIsOpen(true);
  }

  function hideModal() {
    setIsOpen(false);
  }

  return [isOpen, openModal, hideModal];
};

export default useModal;
