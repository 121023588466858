import React from 'react';

import AvailableFilters from '../../../../components/filters/available/available-filters.mobile';
import { mapFilter } from '../helpers';
import { namespace } from '../constants';

export const renderAvailableFilters = (filter, title, isTopBrand) =>
  filter.see_all_text &&
  filter.expanded_values &&
  filter.expanded_values.length > 0 && (
    <span className={`${namespace}-view-all`}>
      <AvailableFilters
        title={filter.see_all_title.text}
        labels={filter.see_all_text}
        icon={false}
        filters={mapFilter(title, filter.expanded_values)}
        isTopBrand={isTopBrand}
        isExpanded
      />
    </span>
  );
