import { arrayOf, bool, shape, string, object } from 'prop-types';

const SpecializedCarouselMobilePropTypes = {
  availableFilters: arrayOf(shape()),
  filter: shape().isRequired,
  isTopBrand: bool,
  title: shape(),
};

const SpecializedCarouselDesktopPropsTypes = {
  deviceType: string,
  filter: shape({
    id: string.isRequired,
    see_all_text: string,
    values: arrayOf(
      shape({
        id: string.isRequired,
        label: shape({
          text: string.isRequired,
          color: string,
          font_family: string,
          font_size: string,
        }).isRequired,
        picture: shape({
          normal: string.isRequired,
          tags: shape({
            alt: string.isRequired,
          }).isRequired,
        }).isRequired,
        subtitle: shape({
          text: string,
        }),
        target: string.isRequired,
      }).isRequired,
    ).isRequired,
    expanded_values: arrayOf(
      shape({
        id: string.isRequired,
        label: shape({
          text: string.isRequired,
          color: string,
          font_family: string,
          font_size: string,
        }).isRequired,
        picture: shape({
          normal: string.isRequired,
          tags: shape({
            alt: string.isRequired,
          }).isRequired,
        }).isRequired,
        subtitle: shape({
          text: string,
        }),
        target: string.isRequired,
      }),
    ),
    modal: object,
  }).isRequired,
  title: shape({
    text: string.isRequired,
  }).isRequired,
};

export { SpecializedCarouselMobilePropTypes, SpecializedCarouselDesktopPropsTypes };
