import React from 'react';

import { FilterLink } from '../../../../../link';
import { namespace } from '../../constants';
import { objValuesPropTypes } from '../../../filters/prop-types';

const SizeFilterRenderer = ({ id, name, results, url }, filterName) => (
  <FilterLink
    className={`${namespace}-size-filter`}
    key={id}
    href={url}
    ariaLabel={`${filterName} ${name} ${results}`}
    title={name}
  >
    <div className={`${namespace}-filter-data`}>
      <span className={`${namespace}-filter-name`}>{name}</span>
    </div>
  </FilterLink>
);

SizeFilterRenderer.propTypes = {
  ...objValuesPropTypes,
};

export default SizeFilterRenderer;
