import React from 'react';

import classnames from 'classnames';
import { CarouselSnappedSlide } from '@andes/carousel-snapped';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';

import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import { namespace, SLIDES_LIMIT } from '../constants';
/**
 * @typedef {Object} SeeMoreProps
 * @property {Object} slide - Los datos del slide del carrusel.
 * @property {Object} slide.picture - Las imágenes asociadas al slide.
 * @property {string} slide.picture.normal - La URL de la imagen normal del slide.
 * @property {Object} slide.label - La etiqueta asociada al slide.
 * @property {string} slide.label.text - El texto de la etiqueta.
 * @property {string} slide.label.color - El color del texto de la etiqueta.
 * @property {string} slide.label.font_family - La familia tipográfica del texto de la etiqueta.
 * @property {string} slide.label.font_size - El tamaño de la fuente del texto de la etiqueta.
 * @property {number} index - El índice del slide en el carrusel.
 */

/**
 * Componente que renderiza un botón "Ver más" dentro de un carrusel especializado.
 *
 * @param {SeeMoreProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el botón "Ver más".
 */
export const getSeeMoreButton = (slide, openModal, index) => (
  <CarouselSnappedSlide key={`${index}-see-more`} className={`${namespace}-specialized-item-slide`}>
    <button type="button" onClick={openModal} className={`${namespace}-specialized-see-more`}>
      <Thumbnail size="80">
        <Image
          alt={slide.picture.tags.alt}
          lazyload={index < SLIDES_LIMIT ? 'off' : 'on'}
          preload={index < SLIDES_LIMIT}
          src={slide.picture.normal}
        />
      </Thumbnail>
      <StyledLabelWrapper
        className={classnames(`${namespace}-specialized-title-short`)}
        color={slide.label.color}
        font_weight={slide.label.font_family}
        size={slide.label.font_size}
        text={slide.label.text}
        as="span"
      />
    </button>
  </CarouselSnappedSlide>
);
