import React, { useMemo } from 'react';

import { CarouselFree } from '@andes/carousel-free';

import { CAROUSEL_SPACING, SpecializedCarouselMobilePropTypes, namespace } from './constants';
import { renderSlidesMobile as renderSlides } from './components';
import withComponentHandler from '../../../../hocs/with-component-handler';
/**
 * @typedef {Object} SpecializedCarouselMobileProps
 * @property {Object} filter - Los datos del filtro asociado al carrusel.
 * @property {Object[]} filter.values - Los valores asociados al filtro.
 * @property {string} filter.id - El identificador único del filtro.
 * @property {string} title - El título del carrusel.
 * @property {boolean} isTopBrand - Indica si el carrusel pertenece a una marca destacada.
 * @property {Object[]} availableFilters - Los filtros disponibles asociados al carrusel.
 */

/**
 * Componente que renderiza un carrusel especializado para dispositivos móviles.
 *
 * @param {SpecializedCarouselMobileProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el carrusel especializado.
 */
const SpecializedCarousel = ({ filter, title, isTopBrand, availableFilters }) => {
  const updatedFilterValues = useMemo(() => Array.from(filter.values), [filter.values]);

  return (
    <CarouselFree spacing={CAROUSEL_SPACING} className={`${namespace}-carousel`}>
      {updatedFilterValues.map((slide, index) =>
        renderSlides(slide, index, filter.id, isTopBrand, availableFilters, title),
      )}
    </CarouselFree>
  );
};

SpecializedCarousel.propTypes = {
  ...SpecializedCarouselMobilePropTypes,
};

export default withComponentHandler(SpecializedCarousel, { componentName: 'SpecializedCarousel' });
