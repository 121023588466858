import React from 'react';
import { string } from 'prop-types';

import VehicleBodyItem from '../../../../../../components/vehicle-body-selector/vehicle-body-item';

const VehicleBodyFilterRenderer = ({ id, name, url, image }) => (
  <VehicleBodyItem key={id} value={{ id, name, url }} image={image} />
);

VehicleBodyFilterRenderer.propTypes = {
  id: string,
  image: string,
  name: string,
  url: string,
};

export default VehicleBodyFilterRenderer;
