import { renderFilterGroup } from './filterUtils';

const regexValue = /[\u0300-\u036f]/g;
const normalizeValue = 'NFD';

const filterName = (item) => item?.toUpperCase()?.normalize(normalizeValue)?.replace(regexValue, '');

export const filterLetter = (values, renderer) => {
  const localValues = JSON.parse(JSON.stringify(values));
  const filter = {};

  let arr = [];

  const orderedValues = localValues.sort((a, b) => {
    const nameA = filterName(a.name);
    const nameB = filterName(b.name);

    if (nameA > nameB) {
      return 1;
    }

    return nameA < nameB ? -1 : 0;
  });

  orderedValues.forEach((value) => {
    if (filter[filterName(value.name[0])]) {
      arr.push(value);
    } else {
      arr = [];
      arr.push(value);
    }

    filter[filterName(value.name[0])] = arr;
  });

  return renderFilterGroup(filter, renderer);
};
