const mapFilter = (title, filterValues) => [
  {
    id: filterValues.id,
    name: title.text,
    values: [],
    showModal: false,
    expanded_values: filterValues.map((value) => ({
      name: value.label.text,
      url: value.target,
    })),
  },
];

const isTitleOneLine = (title) => title.indexOf(' ') === -1;

export { mapFilter, isTitleOneLine };
