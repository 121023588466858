import { arrayOf, bool, number, shape, string } from 'prop-types';

const objLabels = {
  filter: string,
  filter_by: string,
  apply: string,
  clean: string,
  to: string,
  from: string,
  number_separator: string,
  range_separator: string,
  range_title: string,
  minimum: string,
  maximum: string,
  range_names: shape({
    only_to: string,
    only_from: string,
  }),
  continue: string,
};

const objValuesPropTypes = {
  id: string.isRequired,
  name: string.isRequired,
  results: number,
  url: string.isRequired,
  filterId: string,
};

const linkConfig = {
  keepLayout: bool,
  isInternal: bool,
};

const valuesPropTypes = shape(objValuesPropTypes);
const colorValuesPropTypes = {
  ...objValuesPropTypes,
  option: string.isRequired,
  namespace: string.isRequired,
};
const valueWithLinkConfigPropTypes = {
  ...objValuesPropTypes,
  filterId: string,
  linkConfig: shape(linkConfig),
  namespace: string.isRequired,
  accessibilityText: string,
};
const valueLinkPropTypes = {
  ...objValuesPropTypes,
  namespace: string.isRequired,
  filterId: string.isRequired,
  accessibilityText: string,
  ids: shape({
    SUGGESTED_PRODUCTS: string.isRequired,
    FULFILLMENT: string.isRequired,
  }),
};
const filterPropTypes = {
  id: string.isRequired,
  name: string.isRequired,
  fragment: string,
  modal: shape({
    type: string.isRequired,
    labels: shape({
      modal_label: string.isRequired,
      modal_not_found_message: string.isRequired,
      modal_place_holder: string.isRequired,
    }).isRequired,
  }),
  type: string.isRequired,
  values: arrayOf(shape(valuesPropTypes)),
  expanded_values: arrayOf(valuesPropTypes),
};

const objLabelsPropTypes = {
  label: string.isRequired,
  type: string.isRequired,
};

const objUrlTamplate = {
  only_from: string,
  only_to: string,
  from_and_to: string,
};

const objRangeContent = {
  labels: objLabels,
  id: string,
  values: objValuesPropTypes,
  url_templates: objUrlTamplate,
  type: string,
  activeCurrencyId: string,
  fragment: string,
};

const objRenderFilterRange = {
  activeCurrencyId: string,
  fragment: string,
  labels: objLabels,
  modifier: string,
  type: string,
  url_templates: objUrlTamplate,
  values: objValuesPropTypes,
};

export {
  objLabels,
  filterPropTypes,
  valuesPropTypes,
  objValuesPropTypes,
  colorValuesPropTypes,
  objLabelsPropTypes,
  valueWithLinkConfigPropTypes,
  valueLinkPropTypes,
  objRangeContent,
  objRenderFilterRange,
};
