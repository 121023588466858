const searchUrlClient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 5000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { AVAILABLE_FILTER_VALUES, SEARCH_URL_BASE_PATH } = require('../constants/paths');

class Service {
  static getAvailableFilterValues(filterId, filterSelectedValues, restOfSelectedFiltersParams) {
    return searchUrlClient
      .get(
        `${AVAILABLE_FILTER_VALUES}?filter_id=${filterId}&filter_values=${filterSelectedValues}&${restOfSelectedFiltersParams}`,
        {
          headers: {
            Encoding: 'UTF-8',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => response.data);
  }

  static getSearchUrlFilter(params) {
    return searchUrlClient
      .get(`${SEARCH_URL_BASE_PATH}?${params}`, {
        headers: {
          Encoding: 'UTF-8',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data);
  }
}

module.exports = Service;
