import { shape, number, arrayOf, string } from 'prop-types';

const textCarouselPropTypes = {
  filter: shape({
    scroll_position: number,
    values: arrayOf(
      shape({
        id: string.isRequired,
        label: shape({
          text: string.isRequired,
        }).isRequired,
        target: string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export { textCarouselPropTypes };
