import renderMultiSelection from './filters/MultiSelectionRenderer';
import renderColorFilter from './filters/ColorFilterRenderer';
import renderSizeFilter from './filters/SizeFilterRenderer';
import renderVehicleBodyFilter from './filters/VehicleBodyFilterRenderer';
import renderFilter from './filters/FilterLinkRenderer';

export const getContentRenderer = ({ multi_selection, id, image }) => {
  if (multi_selection) {
    return renderMultiSelection;
  }

  switch (id) {
    case 'MAIN_COLOR':
      return renderColorFilter;
    case 'SIZE':
    case 'FILTRABLE_SIZE':
      return renderSizeFilter;
    case 'VEHICLE_BODY_TYPE':
      return image ? renderVehicleBodyFilter : renderFilter;

    default:
      return renderFilter;
  }
};
