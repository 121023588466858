import React from 'react';

import { TextField } from '@andes/textfield';

import { MODAL_TYPES, namespace } from '../constants';

const renderSearchBar = (modal_place_holder, handleChange) => (
  <div className={`${namespace}__filter-bar`}>
    <TextField data-testid="search_bar" placeholder={modal_place_holder} textbox onChange={handleChange} />
  </div>
);

export const getSearchBar = (modalType, modal_place_holder, handleChange) => {
  switch (modalType) {
    case MODAL_TYPES.WITH_SEARCH:
      return renderSearchBar(modal_place_holder, handleChange);
    case MODAL_TYPES.DEFAULT:
      return null;

    default:
      return null;
  }
};
