import React from 'react';

import classnames from 'classnames';

import SpecializedCarousel from './specialized-carousel/desktop';
import { FILTER_INTERVENTION, filterIntervensionDesktopPropTypes, namespace } from './constants';
import withComponentHandler from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} FilterInterventionProps
 * @property {Object} intervention - Los datos de la intervención del filtro.
 * @property {string} intervention.type - El tipo de intervención.
 * @property {string} intervention.title - El título de la intervención.
 * @property {Object} intervention.filter - Los datos del filtro asociado a la intervención.
 * @property {string} deviceType - El tipo de dispositivo (por ejemplo, "desktop" o "tablet").
 */

/**
 * Componente que renderiza una intervención de filtro para escritorio.
 *
 * @param {FilterInterventionProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención de filtro.
 */
const FilterIntervention = ({ intervention: { type, title, filter }, deviceType }) => (
  <div className={classnames(namespace, `${namespace}-container`, `${namespace}-container__${type}`)}>
    {type === FILTER_INTERVENTION.TYPES.FILTER_SPECIALIZED && (
      <SpecializedCarousel type={type} filter={filter} title={title} deviceType={deviceType} />
    )}
  </div>
);

FilterIntervention.propTypes = {
  ...filterIntervensionDesktopPropTypes,
};

export default withComponentHandler(FilterIntervention, { componentName: 'FilterIntervention' });
