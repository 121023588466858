import React from 'react';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { CarouselFreeSlide } from '@andes/carousel-free';

import Link from '../../../../link';
import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import AvailableFilters from '../../../../../components/filters/available/available-filters.mobile';
import {
  BRAND_TYPE,
  SEE_MORE,
  BRAND_THUMBNAIL_SIZE,
  DEFAULT_THUMBNAIL_SIZE,
  LAZYLOAD_THRESHOLD,
  namespace,
} from '../constants';
import { isTitleOneLine } from '../../helpers';

const getThumbnailSize = (filterId, thumbnailSize) =>
  filterId === BRAND_TYPE ? thumbnailSize || BRAND_THUMBNAIL_SIZE : DEFAULT_THUMBNAIL_SIZE;
/**
 * @typedef {Object} SlidesMobileProps
 * @property {Object} slide - Los datos del slide del carrusel.
 * @property {string} slide.id - El identificador único del slide.
 * @property {Object} slide.picture - Las imágenes asociadas al slide.
 * @property {string} slide.picture.normal - La URL de la imagen normal del slide.
 * @property {Object} slide.label - La etiqueta asociada al slide.
 * @property {string} slide.label.text - El texto de la etiqueta.
 * @property {string} slide.label.color - El color del texto de la etiqueta.
 * @property {string} slide.thumbnail_size - El tamaño del thumbnail del slide.
 * @property {number} index - El índice del slide en el carrusel.
 * @property {string} filterId - El identificador del filtro asociado al slide.
 * @property {boolean} isTopBrand - Indica si el slide pertenece a una marca destacada.
 * @property {Object[]} availableFilters - Los filtros disponibles asociados al carrusel.
 * @property {string} title - El título del carrusel.
 */

/**
 * Componente que renderiza un slide del carrusel especializado para dispositivos móviles.
 *
 * @param {SlidesMobileProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el slide del carrusel especializado.
 */
export const renderSlidesMobile = (slide, index, filterId, isTopBrand, availableFilters, title) => {
  const thumbnailSize = getThumbnailSize(filterId, slide.thumbnail_size);

  if (slide.id !== SEE_MORE) {
    return (
      <CarouselFreeSlide key={slide.id} className={`${namespace}-specialized-item-slide`}>
        <Link className={`${namespace}__link`} href={slide.target} filterId={slide.id} title={slide.picture.tags.alt}>
          <Thumbnail size={Number(thumbnailSize)}>
            <Image
              alt=""
              lazyload={index < LAZYLOAD_THRESHOLD ? 'off' : 'on'}
              preload={index < LAZYLOAD_THRESHOLD}
              src={slide.picture.normal}
            />
          </Thumbnail>
          <StyledLabelWrapper
            className={classnames(
              `${namespace}${
                isTitleOneLine(slide.label.text) ? '-specialized-title-short' : '-specialized-title-large'
              }`,
            )}
            color={slide.label.color}
            font_weight={slide.label.font_family}
            size={slide.label.font_size}
            text={slide.label.text}
            as="span"
          />
          {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
        </Link>
      </CarouselFreeSlide>
    );
  }

  return (
    <CarouselFreeSlide key={slide.id} className={`${namespace}-specialized-item-slide`}>
      <Thumbnail size={Number(thumbnailSize)}>
        <Image
          alt={slide.picture.tags.alt}
          lazyload={index < LAZYLOAD_THRESHOLD ? 'off' : 'on'}
          preload={index < LAZYLOAD_THRESHOLD}
          src={slide.picture.normal}
        />
      </Thumbnail>
      {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
      <AvailableFilters
        title={title.text}
        labels={slide.label}
        icon={false}
        filters={availableFilters}
        isTopBrand={isTopBrand}
        isExpanded
        srLabel={title || ''}
      />
    </CarouselFreeSlide>
  );
};
