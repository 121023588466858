const BRAND_TYPE = 'BRAND';
const BRANDS_TYPE = 'BRANDS';
const DEVICE_TYPE_TABLET = 'tablet';
const SEE_MORE = 'see_more';
const DEFAULT_THUMBNAIL_SIZE = 80;
const BRAND_THUMBNAIL_SIZE = 64;
const LAZYLOAD_THRESHOLD = 4;
const CAROUSEL_SPACING = 12;
const SLIDES_LIMIT = 7;
const SLIDES_TO_SHOW_TABLET = 4;
const SPACING = 16;

export {
  BRAND_TYPE,
  BRANDS_TYPE,
  DEVICE_TYPE_TABLET,
  SEE_MORE,
  BRAND_THUMBNAIL_SIZE,
  DEFAULT_THUMBNAIL_SIZE,
  CAROUSEL_SPACING,
  LAZYLOAD_THRESHOLD,
  SLIDES_LIMIT,
  SLIDES_TO_SHOW_TABLET,
  SPACING,
};
