import React from 'react';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import { Thumbnail } from '@andes/thumbnail';
import { CarouselSnappedSlide } from '@andes/carousel-snapped';

import StyledLabelWrapper from '../../../../../components/styled-label/styled-label';
import Link from '../../../../link';
import { SEE_MORE, SLIDES_LIMIT, namespace } from '../constants';
import { isTitleOneLine } from '../../helpers';
import { getSeeMoreButton } from './seeMore';
/**
 * @typedef {Object} SlidesDesktopProps
 * @property {Object} slide - Los datos del slide del carrusel.
 * @property {Object} slide.label - La etiqueta asociada al slide.
 * @property {string} slide.label.text - El texto de la etiqueta.
 * @property {string} slide.label.color - El color del texto de la etiqueta.
 * @property {string} slide.label.font_family - La familia tipográfica del texto de la etiqueta.
 * @property {string} slide.label.font_size - El tamaño de la fuente del texto de la etiqueta.
 * @property {Object} slide.subtitle - El subtítulo asociado al slide.
 * @property {string} slide.subtitle.text - El texto del subtítulo.
 * @property {Function} openModal - La función para abrir el modal.
 * @property {number} index - El índice del slide en el carrusel.
 */

/**
 * Componente que renderiza un slide del carrusel especializado para escritorio.
 *
 * @param {SlidesDesktopProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el slide del carrusel especializado.
 */
export const renderSlidesDesktop = (slide, index, openModal) => {
  if (slide.id !== SEE_MORE) {
    return (
      <CarouselSnappedSlide key={`${index}-${slide.label.text}`} className={`${namespace}-specialized-item-slide`}>
        <Link className={`${namespace}__link`} href={slide.target} filterId={slide.id} title={slide.picture.tags.alt}>
          <Thumbnail size="80">
            <Image
              alt=""
              lazyload={index < SLIDES_LIMIT ? 'off' : 'on'}
              preload={index < SLIDES_LIMIT}
              src={slide.picture.normal}
            />
          </Thumbnail>
          <StyledLabelWrapper
            className={classnames(
              `${namespace}${
                isTitleOneLine(slide.label.text) ? '-specialized-title-short' : '-specialized-title-large'
              }`,
            )}
            color={slide.label.color}
            font_weight={slide.label.font_family}
            size={slide.label.font_size}
            text={slide.label.text}
            as="span"
          />
          {slide.subtitle && <span className={`${namespace}-specialized-subtitle`}>{slide.subtitle.text}</span>}
        </Link>
      </CarouselSnappedSlide>
    );
  }

  return getSeeMoreButton(slide, openModal, index);
};
