import { string, number, shape, arrayOf, oneOfType } from 'prop-types';

const colorCarouselPropTypes = {
  filter: shape({
    values: arrayOf(
      shape({
        id: oneOfType([string, number]).isRequired,
        target: string.isRequired,
        label: shape({
          text: string.isRequired,
        }).isRequired,
        value_id: oneOfType([string, number]).isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

export { colorCarouselPropTypes };
