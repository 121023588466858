import React from 'react';
import { string } from 'prop-types';

import getIcon from '../../../../icons';
import { namespace } from '../constants';

const ModalContentNotFound = ({ labelNotFound }) => (
  <div className="modal-content-not-found">
    {getIcon({
      iconId: 'not_found',
      className: `${namespace}__icon`,
    })}
    <p className="modal-content-not-found__text">{labelNotFound}</p>
  </div>
);

ModalContentNotFound.propTypes = {
  labelNotFound: string,
};

export default ModalContentNotFound;
